import axios from 'axios'
import { api } from './api'
// config
import { HOST_API } from '../config'
import { throws } from 'assert'

/* eslint-disable no-else-return */
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    "ngrok-skip-browser-warning": "any value",
  }
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 403 && response?.data?.code === "token_not_valid") {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
      window.location.href = '/auth/login'
    }
    return response
  },
  async (error) => {

    // Refrescar JWT
    const originalRequest = error.config
    const maxAttempts = 3 // Set the maximum number of attempts
    const retryCount = originalRequest._retryCount || 0 // Get the number of attempts so far
    if ((error.response.status === 401 || error.response.status === 403) && retryCount < maxAttempts && error?.response?.data?.code === 'token_not_valid') {
      // This prop is to check if is the first o second request intent.
      originalRequest._retry = true
      originalRequest._retryCount = retryCount + 1 // Increment the number of attempts

      const refreshToken = window.localStorage.getItem('refreshToken')
      // Get new access token
      try {
      const res = await api.auth.refresh(refreshToken)
      const { access, refresh } = res.data

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`
      // Is necesary reasign the header for retry request
      originalRequest.headers.Authorization = `Bearer ${access}`

      if (access) {
        window.localStorage.setItem('accessToken', access)
      }

      if (refresh) {
        window.localStorage.setItem('refreshToken', refresh)
      }
      // Retry with next credentials
      return axiosInstance(originalRequest)
      } catch (error) {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')

        window.location.href = '/auth/login'
      }
    } else if (retryCount >= maxAttempts) {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
      // Redirect after the maximum number of attempts is reached
      window.location.href = '/auth/login'
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
)

window.axiosInstance = axiosInstance

export default axiosInstance
