import { useEffect, useLayoutEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Navigate, useParams } from 'react-router';
// @mui
import { Box, Container, Typography, Link } from '@mui/material';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths'
// components
import CustomHeader from './CustomHeader';
// import Footer from './Footer';
import Logo from '../../components/logo';
// config
import { COMPANY_NAME } from '../../config';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { id } = useParams()
  const { user, isInitialized, setEventId, isAuthenticated } = useAuthContext()
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  useLayoutEffect(() => {
    setEventId(id)
  }, [id])

  if (!user?.random_slug && isInitialized && isAuthenticated) {
    return (
      <Navigate to={PATH_AUTH.memberships} />
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <CustomHeader />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>

      {/* <Footer /> */}
      <SimpleFooter />
    </Box>
  );
}

const SimpleFooter = () => (
  <Box
    component="footer"
    sx={{
      py: 5,
      textAlign: 'center',
      position: 'relative',
      bgcolor: 'background.default',
    }}
  >
    <Container>
      <Logo sx={{ mb: 1, mx: 'auto' }} />

      <Typography variant="caption" component="div">
        © Todos los derechos reservados
        {/* <br /> Hecho por &nbsp; */}
        <br />
        <Link href={"https://www.amasfac.org/"}> {COMPANY_NAME} </Link>
      </Typography>
    </Container>
  </Box>
);
