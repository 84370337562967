import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { StyledChart } from './components/chart';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';

// ----------------------------------------------------------------------

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function App() {
  const appearance = {
    theme: 'stripe',
  };

  const options = {
    appearance,
  };
  
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <Elements stripe={stripePromise} options={options}>
                <StyledChart />
                  <Router />
              </Elements>
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
