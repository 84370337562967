import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue === null ? defaultValue : JSON.parse(storedValue);
    } catch {
      return storedValue
    }

  });

  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(() => {
          try {
            const parsedValue = JSON.parse(e.newValue)
            if (e.newValue) return parsedValue
            return e.newValue
          } catch {
            return e.newValue
          }
        });
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      try {
        const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
        localStorage.setItem(key, JSON.stringify(result));
        return result;
      } catch {
        return defaultValue
      }
    });
  };

  return [value, setValueInLocalStorage];
}
