import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Link, Tooltip, TextField } from '@mui/material';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// routes
import { PATH_DOCS, PATH_MINIMAL_ON_STORE, PATH_AUTH } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import Label from '../../components/label';
import Image from '../../components/image';
//
import navConfig from './nav/config';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import Searchbar from '../dashboard/header/Searchbar';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function CustomHeader() {

  const navigate = useNavigate();

  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const { isAuthenticated, logout, user } = useAuthContext()

  useEffect(() => {

  }, [isAuthenticated])

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            backgroundColor: "transparent",
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Logo sx={{ mr: "20px" }} />
          {user?.image && user?.name &&(
            <Tooltip 
              title="Cambiar membresía"              
            >
              <Box display={'flex'} alignItems={'center'} gap={2} onClick={() => navigate(PATH_AUTH.memberships)} sx={{ cursor: 'pointer'}}>
                <Label sx={{ color: "text.primary", background: theme.palette.background.default, cursor: 'pointer'}}>{user?.name}</Label>
                <Image
                  variant="success"
                  src={user?.image}
                  alt="img"
                  height="30px"
                  sx={{ width: "30px", maxHeight: "30px !important", borderRadius: "4px" }}
                />
              </Box>
              </Tooltip>
          )}

          {/* <Link href={PATH_DOCS.changelog} target="_blank" rel="noopener" underline="none" sx={{ ml: 1 }}>
            <Label color="info"> v4.0.0 </Label>
          </Link> */}

          <Box sx={{ flexGrow: 1 }} />


          {/* {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />} */}

          <Button variant="contained" rel="noopener" href={ !isAuthenticated ? PATH_AUTH.login : '/'} onClick={isAuthenticated ? logout : null }>
            {
              isAuthenticated ? 'Logout' : 'Login'
            }
          </Button>

          {/* {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />} */}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
