import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
// componentes
import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <Page title="login">
      <Login />
    </Page>
  );
}
