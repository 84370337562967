import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const Memberships = Loadable(lazy(() => import('../pages/auth/Memberships')))
 const PermissionDeniedPage = Loadable(lazy(() => import('../pages/dashboard/PermissionDeniedPage')));

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Custom404 = Loadable(lazy(() => import('../pages/event/components/Page404')));
export const Custom403 = Loadable(lazy(() => import('../pages/event/components/Page403')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Home = Loadable(lazy(() => import('../pages/event/Home')));
export const AboutPage = Loadable(lazy(() => import('../pages/event/AboutPage')));

export const EventLayout = Loadable(lazy(() => import('../pages/event/EventLayout')));
export const EventPage = Loadable(lazy(() => import('../pages/event/event-wizard/EventPage')));
export const TicketsPage = Loadable(lazy(() => import('../pages/event/event-wizard/TicketsPage')));
export const ClientDetailPage = Loadable(lazy(() => import('../pages/event/event-wizard/ClientDetailPage')));
export const PaymentEventPage = Loadable(lazy(() => import('../pages/event/event-wizard/PaymentEventPage')));
export const ConfirmPage = Loadable(lazy(() => import('../pages/event/Confirmation')));
export const StripeConfirmPage = Loadable(lazy(() => import('../pages/event/PublicConfirmation')));
export const DeclainedPayment = Loadable(lazy(() => import('../pages/event/DeclainedPayment')));
export const TicketAssistance = Loadable(lazy(() => import('../pages/event/TicketAssistance')));
export const TicketShow = Loadable(lazy(() => import('../pages/event/TicketShow')));

export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));

// USE LATER
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
