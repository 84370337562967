// import {  enqueueSnackbar } from 'notistack'
import { QueryClient } from '@tanstack/react-query'

function queryErrorHandler (error) {
  const title = error instanceof Error ? error.message : 'Error al conectar con servidor'

  console.warn(title)

  // enqueueSnackbar(title, {
  //   variant: 'error',
  //   persist: true,
  //   preventDuplicate: true
  // })
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 5,
      // refetchOnWindowFocus: false,
      onError: queryErrorHandler
    }
  }
})

export default queryClient
