import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  ComingSoonPage,
  Memberships,
  Page500,
  Page403,
  Page404,
  Home,
  // TicketAssistance,
  TicketShow,
  MaintenancePage,
  EventLayout,
  EventPage,
  TicketsPage,
  ClientDetailPage,
  PaymentEventPage,
  ConfirmPage,
  StripeConfirmPage,
  DeclainedPayment,
  PaymentPage,
  Custom404,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        {
          path: 'memberships',
          element: (
            <AuthGuard>
              <Memberships />
            </AuthGuard>
          )
        },
        // { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        // {
        //   element: <CompactLayout />,
        //   children: [
        //     { path: 'reset-password', element: <ResetPasswordPage /> },
        //     { path: 'new-password', element: <NewPasswordPage /> },
        //     { path: 'verify', element: <VerifyCodePage /> },
        //   ],
        // },
      ],
    },
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Home />, index: true  },
        { path: ':id',
          element: <EventLayout />,
          children: [
            { path: '', element: <EventPage /> },
            { path: 'tickets', element: <TicketsPage /> },
            { path: 'informacion', element: <ClientDetailPage /> },
            { path: 'pago', element: <PaymentEventPage /> },
            { path: 'confirmacion', element: <ConfirmPage /> },
            // { path: 'cancelado', element: <DeclainedPayment /> },
          ]
        },
      ],
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { path: 'pricing', element: <PricingPage /> },
    //     { path: 'payment', element: <PaymentPage /> },
    //   ],
    // },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        // { path: '404', element: <Page404 /> },
        { path: '404', element: <Custom404 /> },
        { path: '403', element: <Page403 /> },

        { path: 'confirmacion-stripe/:checkoutSessionId', element: <StripeConfirmPage /> },
        // {
        //   path: 'assistance',
        //   children: [
        //     { element: <Custom404 />, index: true },
        //     { path: ':id', element: <TicketAssistance /> },
        //   ],
        // },
      ],
    },
    {
      path: 'ticket',
      children: [
        { element: <Custom404 />, index: true },
        { path: ':id', element: <TicketShow /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
