import axiosInstance from "./axios"

export const api = {

  auth: {
    login (credentials) {
      return axiosInstance.post('/api/v1/auth/', credentials)
    },
    logout () {
      return axiosInstance.post('/rest-auth/logout/')
    },
    refresh (refresh) {
      return axiosInstance.post('/api/v1/auth/refresh/', { refresh })
    }
  },

  memberships: {
    getMemberMemberships(){
      return axiosInstance.get(`/api/v1/member/memberships/`, {
        validateStatus: (status) => {
          return status < 500
        },
      })
    }
  },
  
  tickets: {
    private: {
      getBySlug(uuid){
        return axiosInstance.get(`/api/v1/member/tickets/${uuid}/`)
      },
    }
  },

  events: {
    getEventDetail(slug) {
      return axiosInstance.get(`/api/v1/admin/events/${slug}`, {
        validateStatus: (status) => {
          return status < 500
        },
        headers: {
          Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc1MDkzOTI5LCJpYXQiOjE2NzUwOTM2MjksImp0aSI6IjQxYjNmYzVhMmEyYjRlZmVhZGZkOTYxYzM4YmNkMzQ4IiwidXNlcl9pZCI6Mn0.KOHa4Cs16MgSjVLoCCfQZH176TCiadQfQHbv65XPZZU'
        }
      })
    },
    getPublicEvent(slug){
      return axiosInstance.get(`/api/v1/public/events/${slug}`, {
        validateStatus: (status) => {
          return status < 500
        },
      })
    },
    registerCheckin(uuid){
      return axiosInstance.get(`/api/v1/admin/tickets/${uuid}/checkin/`)
    },
  }
}

export default api